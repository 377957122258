import * as ActionTypes from '../actionTypes';

export const getOffers = (payloadData, meta) => ({
  type: ActionTypes.GET_OFFERS,
  payload: payloadData,
  meta
});

export const getOffersSuccess = (value, action) => ({
  type: ActionTypes.GET_OFFERS_SUCCESS,
  value,
  meta: action.meta
});

export const getOffersError = (error, action) => ({
  type: ActionTypes.GET_OFFERS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getUserAvailablePoints = meta => ({
  type: ActionTypes.GET_USER_AVAILABLE_POINTS,
  // payload: payloadData,
  meta
});

export const getUserAvailablePointsSuccess = (value, action) => ({
  type: ActionTypes.GET_USER_AVAILABLE_POINTS_SUCCESS,
  value,
  meta: action.meta
});

export const getUserAvailablePointsError = (error, action) => ({
  type: ActionTypes.GET_USER_AVAILABLE_POINTS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCoupons = (payloadData, meta) => ({
  type: ActionTypes.GET_COUPONS,
  payload: payloadData,
  meta
});

export const getCouponsSuccess = (value, action) => ({
  type: ActionTypes.GET_COUPONS_SUCCESS,
  value,
  meta: action.meta
});

export const getCouponsError = (error, action) => ({
  type: ActionTypes.GET_COUPONS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getTipsListing = (payloadData, meta) => ({
  type: ActionTypes.TIPS_LISTING_API,
  payload: payloadData,
  meta
});

export const getTipsListingSuccess = (value, action) => ({
  type: ActionTypes.TIPS_LISTING_API_SUCCESS,
  value,
  meta: action.meta
});

export const getTipsListingError = (error, action) => ({
  type: ActionTypes.TIPS_LISTING_API_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getUserCoupons = (payloadData, meta) => ({
  type: ActionTypes.GET_USER_COUPONS,
  payload: payloadData,
  meta
});

export const getUserCouponsSuccess = (value, action) => ({
  type: ActionTypes.GET_USER_COUPONS_SUCCESS,
  value,
  meta: action.meta
});

export const getUserCouponsError = (error, action) => ({
  type: ActionTypes.GET_USER_COUPONS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getFreeCrowns = (payloadData, meta) => ({
  type: ActionTypes.GET_FREE_CROWN,
  payload: payloadData,
  meta
});

export const getFreeCrownsSuccess = (value, action) => ({
  type: ActionTypes.GET_FREE_CROWN_SUCCESS,
  value,
  meta: action.meta
});

export const getFreeCrownsError = (error, action) => ({
  type: ActionTypes.GET_FREE_CROWN_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getLimitedDeals = (payloadData, meta) => ({
  type: ActionTypes.GET_LIMITED_DEALS,
  payload: payloadData,
  meta
});

export const getLimitedDealsSuccess = (value, action) => ({
  type: ActionTypes.GET_LIMITED_DEALS_SUCCESS,
  value,
  meta: action.meta
});

export const getLimitedDealsError = (error, action) => ({
  type: ActionTypes.GET_LIMITED_DEALS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getTopDeals = (payloadData, meta) => ({
  type: ActionTypes.GET_TOP_DEALS,
  payload: payloadData,
  meta
});

export const getTopDealsSuccess = (value, action) => ({
  type: ActionTypes.GET_TOP_DEALS_SUCCESS,
  value,
  meta: action.meta
});

export const getTopDealsError = (error, action) => ({
  type: ActionTypes.GET_TOP_DEALS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getDailyRewards = (payloadData, meta) => ({
  type: ActionTypes.GET_DAILY_REWARDS,
  payload: payloadData,
  meta
});

export const getDailyRewardsSuccess = (value, action) => ({
  type: ActionTypes.GET_DAILY_REWARDS_SUCCESS,
  value,
  meta: action.meta
});

export const getDailyRewardsError = (error, action) => ({
  type: ActionTypes.GET_DAILY_REWARDS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCrownHistory = (payloadData, meta) => ({
  type: ActionTypes.GET_CROWN_HISTORY,
  payload: payloadData,
  meta
});

export const getCrownHistorySuccess = (value, action) => ({
  type: ActionTypes.GET_CROWN_HISTORY_SUCCESS,
  value,
  meta: action.meta
});

export const getCrownHistoryError = (error, action) => ({
  type: ActionTypes.GET_CROWN_HISTORY_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getBkWallData = (payloadData, meta) => ({
  type: ActionTypes.GET_BKWALL_DATA,
  payload: payloadData,
  meta
});

export const getBkWallDataSuccess = (value, action) => ({
  type: ActionTypes.GET_BKWALL_DATA_SUCCESS,
  value,
  meta: action.meta
});

export const getBkWallDataError = (error, action) => ({
  type: ActionTypes.GET_BKWALL_DATA_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCartOffers = (payloadData, meta) => ({
  type: ActionTypes.GET_CART_OFFERS,
  payload: payloadData,
  meta
});

export const getCartOffersSuccess = (value, action) => ({
  type: ActionTypes.GET_CART_OFFERS_SUCCESS,
  value,
  meta: action.meta
});

export const getCartOffersError = (error, action) => ({
  type: ActionTypes.GET_CART_OFFERS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

//OrganisationList
export const getOrganizationList = (payloadData, meta) => ({
  type: ActionTypes.GET_ORGANIZATION_LIST,
  payload: payloadData,
  meta
});

export const getOrganizationListSuccess = (value, action) => ({
  type: ActionTypes.GET_ORGANIZATION_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getOrganizationListError = (error, action) => ({
  type: ActionTypes.GET_ORGANIZATION_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

//creating club offer
export const createClubOfferOnboarding = (payloadData, meta) => ({
  type: ActionTypes.CREATE_CLUB_OFFER_ONBOARDING,
  payload: payloadData,
  meta
});

export const createClubOfferOnboardingSuccess = (value, action) => ({
  type: ActionTypes.CREATE_CLUB_OFFER_ONBOARDING_SUCCESS,
  value,
  meta: action.meta
});

export const createClubOfferOnboardingError = (error, action) => ({
  type: ActionTypes.CREATE_CLUB_OFFER_ONBOARDING_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});
