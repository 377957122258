import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BKLOGO from '../../../assets/images/bkLogo.png';
import Button from '../button';
import VerifyOTP from '../login-verify-otp';
import Timer from '../timer';
import SocialLoginContainer from '../../containers/social-login';
import { trackEvent } from '../../../analytics_wrapper';
import { RESEND_OTP } from '../../../analyics_constants';
import { SessionStorage } from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';

class LoginOtp extends Component {
  constructor() {
    super();
    this.state = {
      otpValue: '',
      showResend: false
    };
  }

  componentDidMount() {
    const { otpDisable, verifyOtpError } = this.props;
    if (
      document &&
      document.getElementsByClassName('login-otp') &&
      document.getElementsByClassName('login-otp')[0]
    )
      document
        .getElementsByClassName('login-otp')[0]
        .addEventListener('keyup', this.enterListener);
    if (verifyOtpError) {
      this.setState({ showResend: true });
    }
  }

  componentWillUnmount() {
    if (
      document &&
      document.getElementsByClassName('login-otp') &&
      document.getElementsByClassName('login-otp')[0]
    )
      document
        .getElementsByClassName('login-otp')[0]
        .removeEventListener('keyup', this.enterListener);
  }

  enterListener = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.onClick();
    }
  };

  onChangeHandler = (id, value) => {
    this.setState({ otpValue: value });
  };

  onClick = () => {
    const { otpValue } = this.state;
    if (!(otpValue.length === 4)) {
      alert('enter otp');
    } else {
      const { onVerifyOtpClick } = this.props;
      onVerifyOtpClick(otpValue);
    }
  };

  resendOtpClickHandler = () => {
    const { resendOtpClickHandler, theme, mobileNumber } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    this.setState({ otpValue: '', showResend: false });
    resendOtpClickHandler();
  };

  onShowResend = (value, time) => {
    if (time === 0) {
      this.setState({ showResend: true });
    }
  };

  render() {
    const {
      mobileNumber,
      onChangeClick,
      theme,
      verifyOtpError,
      otpDisable,
      onFacebookLogin,
      responseGoogle,
      showSocialLogin
    } = this.props;
    const { resendOtpClickHandler } = this;
    const { otpValue, showResend } = this.state;

    return (
      <div className={`login-otp ${theme}`}>
        <img src={BKLOGO} width="50" height="50" />
        <p className="login-otp__title">Login</p>
        <div className="login-otp__content">
          <p className="login-otp__description">
            An OTP has been sent to{' '}
            <u onClick={onChangeClick}>{mobileNumber}</u>
          </p>
        </div>
        {/* <div className="login-otp__content">
          <p
            className="login-otp__change"
            onClick={onChangeClick}
            role="presentation"
          >
            Change
          </p>
        </div> */}
        <div>
          <VerifyOTP
            theme={theme}
            onChange={this.onChangeHandler}
            // handleKeyUp={this.handleKeyUp}
            verifyOtpError={verifyOtpError}
            otpDisable={otpDisable}
          />
        </div>
        {verifyOtpError && (
          <div className="login-otp__resend-wrapper">
            <p className="login-otp__error"> {verifyOtpError} </p>
          </div>
        )}
        <div className="login-otp__button">
          <Button
            theme={theme}
            content="verify otp"
            type="block"
            size="is-medium"
            onClick={this.onClick}
            className={`${
              otpValue.length !== 4 || otpDisable
                ? 'login-otp__button_inactive'
                : 'login-otp__button_active'
            }`}
            disabled={otpValue.length !== 4 || otpDisable}
          />
        </div>

        {showResend || verifyOtpError ? (
          <p
            className="login-otp__change"
            role="presentation"
            onClick={() => resendOtpClickHandler()}
          >
            <u>Re-send OTP</u>
          </p>
        ) : (
          <Timer seconds={30} onShow={this.onShowResend} />
        )}

        {showSocialLogin && (
          <SocialLoginContainer
            onFacebookLogin={onFacebookLogin}
            responseGoogle={responseGoogle}
          />
        )}
      </div>
    );
  }
}

LoginOtp.propTypes = {
  onVerifyOtpClick: PropTypes.func,
  mobileNumber: PropTypes.string,
  mobileNumberBtnTxt: PropTypes.string
};

LoginOtp.defaultProps = {
  onVerifyOtpClick: () => {},
  mobileNumberBtnTxt: '',
  mobileNumber: ''
};

export default LoginOtp;
