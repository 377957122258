/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FeedbackImage from '../../../assets/images/Feedback/burger_feedback.svg';
import HappyEmoji from '../../../assets/images/Feedback/happy_emoji.svg';
import SadEmoji from '../../../assets/images/Feedback/sad_emoji.svg';
import { getFeedback } from '../../burger-king-lib/redux/cart/actions';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import Button from '../../components/button';
import OffersToast from '../../components/offers-toast';
import SliderComponent from '../../components/range-slider';
import { ROUTES as _routes } from '../../constants/app-constants';
import FeedbackOrderDetail from '../order-detail-feedback';
import './index.scss';
import { trackEvent } from '../../../analytics_wrapper';
import { rate_order } from '../../../analyics_constants';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';

class Feedback extends React.Component {
  constructor() {
    super();
    this.state = {
      // user_id: SessionStorage.get(LocalSessionKey.USER_ID),
      remarks: '',
      rating: null,
      ratings: null,
      quantity: '',
      hygiene: '',
      ambience: '',
      freshness: '',
      taste: '',
      temperature: '',
      app: null,
      ease: '',
      speed: '',
      payment: '',
      // is_right: false,
      active: false,
      isMobileView: this.isMobileView(),
      dataFeed: {},
      address: {},
      id: '',
      toastMessage: '',
      type: '',
      range: 5,
      isSuccess: false
    };
  }

  componentDidMount = () => {
    let { dataFeed, address, id, type } = this.state;
    const { location } = this.props;
    const { state } = location;
    if (state) {
      dataFeed = state.order;
      address = dataFeed.address;
      id = state.id;
      type = dataFeed.cart_added_for;
      this.setState({ dataFeed, address, id, type });
    } else {
      const { data, orderID } = this.props;
      dataFeed = data;
      address = data.address;
      id = orderID;
      type = dataFeed.cart_added_for;
      this.setState({ dataFeed, address, id, type });
    }
  };

  componentDidUpdate(prevProps) {
    const { feedbackData } = this.props;
    if (
      feedbackData &&
      feedbackData.feedback &&
      feedbackData.feedback !== prevProps.feedbackData.feedback
    ) {
      if (feedbackData.feedback.content && feedbackData.feedback.error === 0) {
        alert(feedbackData.feedback.content);
        this.onToastMsg();
      }
      if (feedbackData.feedback.error === 1)
        alert(feedbackData.feedback.message);
    }
  }

  onChangeRatingButton = (id, value) => {
    this.setState({ [id]: value });
  };

  onToastMsg = () => {
    const { isMobileView } = this.state;
    if (isMobileView) {
      this.goBack();
    } else {
      const { onCloseHandler } = this.props;
      this.setState({
        toastMessage: 'Feedback form submitted successfully',
        isSuccess: true
      });
      onCloseHandler();
    }
  };

  onSubmitClick = () => {
    const { range } = this.state;
    const {
      fromTickets,
      FeedbackApiV2,
      data,
      closeRating,
      updateFeedbackApi,
      callUpdateFeedbackApi
      // FeedbackApiV2
    } = this.props;
    // alert(fromTickets);
    if (!fromTickets) {
      // console.log(fromTickets, 'data', FeedbackApiV2);
      FeedbackApiV2(data, range);
      if (range > 3) {
        this.setState({
          isSuccess: true
        });
      } else {
        this.setState({
          toastMessage: 'Contact Our Support Team Through Virtual Assistant'
        });
        window.YellowMessengerPlugin.openBot();
        closeRating();
        // const { onCloseHandler } = this.props;
        // onCloseHandler();
      }
    } else {
      // alert('call api');
      callUpdateFeedbackApi(data, range);
      this.setState({
        isSuccess: true
      });
    }
    const { theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);

    trackEvent(rate_order, {
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      order_id: data?.order_id,
      overall_rating: range,
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      // remarks: payload.remarks,
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
  };

  toastDestroyHandler = () => {
    this.setState({ toastMessage: '' });
  };

  onCloseHandler = () => {
    const { onCloseHandler } = this.props;
    onCloseHandler();
  };

  handleChange = (Id, val, e) => {
    const { target } = e;
    const { value } = target;
    this.setState({ remarks: value, active: true });
  };

  onChangeRating = (id, value) => {
    this.setState({ [id]: value });
  };

  isMobileView = () => {
    if (window.innerWidth > 1023) {
      return false;
    }
    return true;
  };

  rightContentClickHandler = () => {
    const { history } = this.props;
    history.push(_routes.HELP_SUPPORT);
  };

  goBack = () => {
    const { history } = this.props;
    history.go(-1);
    this.setState({ toastMessage: 'Feedback form submitted successfully' });
  };

  onChangeSlide = value => {
    this.setState({ range: value });
  };

  render() {
    const {
      isMobileView,
      dataFeed,
      toastMessage,
      range,
      isSuccess
    } = this.state;
    const {
      theme,
      closeRating,
      fromTickets,
      FeedbackApiV2,
      data,
      from,
      getOrderHistoryData
    } = this.props;
    // const { cart_added_for } = dataFeed;
    // const { closeRating } = this.props;
    // alert(isMobileView);
    // console.log(dataFeed, 'dataFeed', data);

    const Dom = !isSuccess ? (
      <>
        <div className={`feedback-form ${theme}`}>
          {isMobileView && (
            <AccountSubSecTitle
              title="Rate your Experience"
              goBackOnClick={this.goBack}
              theme={theme}
              rightContent="Help"
              rightContentClick={() => this.rightContentClickHandler()}
            />
          )}
          {/* isMobileView */}
          <div
            style={{
              margin: !isMobileView && '0 10px'
            }}
          >
            <FeedbackOrderDetail
              isTrack
              data={dataFeed}
              dat1={data}
              theme={theme}
            />
          </div>

          <div className="feedback-form_fullContent">
            <div className="feedback-form_header new-class">
              <img
                src={FeedbackImage}
                alt="burger"
                className="feedback-form__image"
                style={{
                  marginTop: '0.5rem'
                }}
              />
              <br />
              {!isMobileView && (
                <p className="feedback-form__description">
                  Rate your Experience
                </p>
              )}
              <img
                src={range > 3 ? HappyEmoji : SadEmoji}
                alt="burger"
                className="feedback-form__image"
                style={{
                  width: '10rem'
                }}
              />
              <SliderComponent
                onChangeSlide={this.onChangeSlide}
                number={range}
                theme={theme}
              />
            </div>
            <div className="feedback-form__submit custom-css">
              <Button
                theme={theme}
                content="Submit"
                className="feedback-form__submit-btn custom"
                // style={{ width: '200px' }}
                onClick={this.onSubmitClick}
              />
            </div>
            {toastMessage ? (
              <OffersToast
                content={toastMessage}
                destroyHanlder={this.toastDestroyHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    ) : (
      <>
        <div className={`feedback-form ${theme}`}>
          {isMobileView && (
            <AccountSubSecTitle
              title="Rate your Experience"
              goBackOnClick={this.goBack}
              theme={theme}
              rightContent="Help"
              rightContentClick={() => this.rightContentClickHandler()}
            />
          )}
          <div className="feedback-form_fullContent">
            <div className="feedback-form_header new-class">
              <p className="feedback-form__description">
                Hey Whopperati!
                <br /> Thanks, Order Again.
              </p>
              <br />
              <img
                src={HappyEmoji}
                alt="burger"
                className="feedback-form__image"
                style={{
                  width: '20rem',
                  marginBottom: '3rem'
                }}
              />
            </div>
            <br />
            <br />
            <br />

            <div className="feedback-form__submit custom-css">
              <Button
                theme={theme}
                content="Done"
                className="feedback-form__submit-btn custom"
                onClick={() => {
                  if (from === 'Recent Orders') {
                    getOrderHistoryData();
                  } else {
                    this.props.history.push('/');
                  }
                  closeRating('close');
                }}
              />
            </div>
            {toastMessage ? (
              <OffersToast
                content={toastMessage}
                destroyHanlder={this.toastDestroyHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
    return Dom;
  }
}

const mapStateToProps = state => {
  return {
    feedbackData: state.feedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFeedbackApiData: payloadData => dispatch(getFeedback(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Feedback)
);
