import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import SavedAddressessList from '../../containers/saved-addressess-list';
import Modal from '../../containers/modal';
import SetAddress from '../../components/set-address';
import AccountSubSecTitle from '../../components/accout-subsec-title';

import {
  getAddressList,
  addAddress,
  getDeleteAddress
} from '../../burger-king-lib/redux/address/actions';

import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import Cancelpop from '../../components/cancel-popover';
import { getOutletChange1 } from '../../burger-king-lib/redux/cart/actions';
import { PureComponent } from 'react';
import { deliveryAddressStorage } from '../../utils/services';
import { trackEvent } from '../../../analytics_wrapper';
import { add_address } from '../../../analyics_constants';

class SavedAddressess extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addressList: [],
      showLoader: true,
      showModal: false,
      editAddressData: {}
    };
    this.isAlertShowedOnce = true;
    this.editMode = false;
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.search.length) {
      const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
      if (!Boolean(isLoggedIn)) {
        this.setState({
          offerApply: true
        });
      }
    }
    const { getAddressListData } = this;
    getAddressListData();
  }

  componentDidUpdate(prevProps) {
    const { addressData } = this.props;
    if (
      addressData &&
      addressData.getAddressList &&
      addressData.getAddressList !== prevProps.addressData.getAddressList
    ) {
      if (addressData.getAddressList.content) {
        this.setAddressList(addressData.getAddressList.content);
      }
      if (addressData.getAddressList.message) this.toggleLoader();
      if (
        addressData.getAddressList.error &&
        addressData.getAddressList.message === 'No Address'
      )
        this.setAddressList([]);
    }
    if (
      addressData &&
      addressData.addAddress !== prevProps.addressData.addAddress
    ) {
      if (addressData.addAddress.error === 0) {
        if (!this.isAlertShowedOnce) {
          this.addAddressSuccessHandler();
          this.isAlertShowedOnce = true;
          if (!this.editMode) alert(addressData.addAddress.message);
          else alert('Address edited successfully');
        }
      } else if (addressData.addAddress.error) {
        alert(addressData.addAddress.message);
      }
    }

    if (
      addressData &&
      addressData.deleteAddress !== prevProps.addressData.deleteAddress
    ) {
      if (addressData.deleteAddress.error === 0) {
        if (!this.isAlertShowedOnce) {
          this.getAddressListData();
          this.isAlertShowedOnce = true;
          alert('Address deleted successfully');
        }
      } else if (
        addressData.deleteAddress.error &&
        addressData.deleteAddress.message
      ) {
        alert(addressData.deleteAddress.message);
      }
    }
  }

  getAddressListData = () => {
    const userId = LocalStorage.get(LocalSessionKey.USER_ID);
    if (userId) {
      const { getAddressListData } = this.props;
      getAddressListData();
    }
  };

  addAddressSuccessHandler = () => {
    this.getAddressListData();
  };

  setAddressList = data => {
    this.setState({ addressList: data });
  };

  toggleLoader = () => {
    this.setState({ showLoader: false });
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  addAddressClickHandler = () => {
    this.editMode = false;
    this.setState({ showModal: true });
  };

  getOfferData = () => {
    setTimeout(() => {
      this.setState({ offerApply: false });
      this.props.history.push('/');
    }, 2000);
    return (
      <div className="offerlisting_offer">
        <p className="offerlisting_offer--text">Alert</p>
        {/* <h3 className="offerlisting_offer--heading">
          Kindly Login to continue
        </h3> */}
        <p className="offerlisting_offer--text">Kindly Login to continue.</p>
        <p className="offerlisting_offer--notify"> Login and Re-try</p>
      </div>
    );
  };

  deleteAddressClickHandler = data => {
    const { getDeleteAddressData } = this.props;
    const { id } = data;
    const payload = { id };
    getDeleteAddressData(payload);
    this.setState({ showLoader: true });
    this.isAlertShowedOnce = false;
  };

  goBackSetAddressClickHandler = () => {
    this.closeModal();
  };

  onSaveAddressClickHandler = payload => {
    this.closeModal();
    const { addAddressData, theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    this.isAlertShowedOnce = false;
    addAddressData(payload);
    trackEvent(add_address, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      lat: payload?.latitude,
      long: payload?.longitude,
      location: payload?.location,
      address: payload?.address,
      landmark: payload?.landmark,
      save_as: payload?.save_as,
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0,
      state: payload?.state,
      store_name: addressInfo?.outlet_name,
      store_id: addressInfo.outlet_id
    });
  };

  closeModal = () => {
    this.setState({ showModal: false, editAddressData: {} });
  };

  editAddressClickHanlder = data => {
    this.editMode = true;
    this.setState({ showModal: true, editAddressData: data });
  };

  savedAddressClickHndler = data => {
    const val = {
      ...data,
      outlet_name: 'location' in data ? data.location : ''
    };
    window.localStorage.setItem(
      'LAT_LNG',
      JSON.stringify({ lat: data.latitude, lng: data.longitude })
    );
    deliveryAddressStorage.set(data);
    const { getOutletChangeData, history } = this.props;
    getOutletChangeData(val);
    history.push('/');
  };

  render() {
    const {
      goBackOnClick,
      addAddressClickHandler,
      deleteAddressClickHandler,
      editAddressClickHanlder,
      savedAddressClickHndler
    } = this;
    const {
      addressList,
      showLoader,
      showModal,
      editAddressData,
      offerApply
    } = this.state;
    const { theme } = this.props;
    return (
      <div className="saved-address_wrapper">
        {offerApply && (
          <Cancelpop content children={this.getOfferData()} theme={theme} />
        )}
        <div className="saved-address">
          <AccountSubSecTitle
            title="Saved Addresses"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
          <div className="saved-address__list">
            <SavedAddressessList
              addressList={addressList}
              isLoggedIn
              addAddressClickHandler={addAddressClickHandler}
              showLoader={showLoader}
              deleteAddressClick={deleteAddressClickHandler}
              editAddressClick={editAddressClickHanlder}
              theme={theme}
              savedAddressClickHndler={savedAddressClickHndler}
            />
          </div>
        </div>
        {showModal ? (
          <Modal
            hideClose
            onCloseHandler={this.closeModal}
            contentCustomClass={window.innerWidth < 1023 ? 'fullWidth' : ''}
          >
            <SetAddress
              editData={editAddressData}
              goBackSetAddressClick={this.goBackSetAddressClickHandler}
              onSaveAddressClick={this.onSaveAddressClickHandler}
              theme={theme}
            />
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addressData: state.address
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAddressListData: payloadData => dispatch(getAddressList(payloadData)),
    addAddressData: payloadData => dispatch(addAddress(payloadData)),
    getDeleteAddressData: payloadData =>
      dispatch(getDeleteAddress(payloadData)),
    getOutletChangeData: payloadData => dispatch(getOutletChange1(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SavedAddressess)
);
