export const web_launch = 'web_launch';
export const location = 'location';
export const delivery_mode = 'delivery_mode';
export const dinein_mode = 'dinein_mode';
export const takeaway_mode = 'takeaway_mode';
export const select_delivery_location = 'select_delivery_location';
export const select_dine_store = 'select_dine_store';
export const product_search = 'product_search';
export const homepage = 'homepage';
export const kingdeals_homepage = 'kingdeals_homepage';
export const nearbystore_homepage = 'nearbystore_homepage';
export const category_homepage = 'category_homepage';
export const delivery_service = 'delivery_service';
export const hamburger_menu = 'hamburger_menu';
export const homescreen_banner = 'homescreen_banner';
export const kingdeals_banner = 'kingdeals_banner';
export const explore_menu = 'explore_menu';
export const store_search = 'store_search';
export const category_plp = 'category_plp';
export const product_click = 'product_click';
export const filter_veg = 'filter_veg';
export const filter_non_veg = 'filter_non_veg';
export const add_to_cart = 'add_to_cart';
export const view_order = 'view_order';
export const cart_sides = 'cart_sides';
export const view_offers = 'view_offers';
export const coupon_apply = 'coupon_apply';
export const coupon_remove = 'coupon_remove';
export const set_delivery_address = 'set_delivery_address';
export const checkout = 'checkout';
export const login_attempt = 'login_attempt';
export const get_otp = 'get_otp';
export const resend_otp = 'resend_otp';
export const verify_otp = 'verify_otp';
export const login_successful = 'login_successful';
export const update_user = 'update_user';
export const login_failed = 'login_failed';
export const notifications_settings = 'notifications_settings';
export const terms_conditions = 'terms_conditions';
export const privacy_policy = 'privacy_policy';
export const faqs = 'faqs';
export const settings = 'settings';
export const crown_history = 'crown_history';
export const saved_address = 'saved_address';
export const saved_kingdeals = 'saved_kingdeals';
export const recent_orders = 'recent_orders';
export const add_address = 'add_address';
export const rate_order = 'rate_order';
export const logout = 'logout';
export const edit_profile = 'edit_profile';
export const redeem_attempt = 'redeem_attempt';
export const redeem_successful = 'redeem_successful';
export const redeem_failed = 'redeem_failed';
export const purchase_cancelled = 'purchase_cancelled';
export const new_purchase = 'new_purchase';
export const last_order = 'last_order';
export const first_purchase = 'first_purchase';

// Doubt
export const make_payment = 'make_payment'; // Should be discussed
