import './index.scss';

import React, { Component } from 'react';
import moment from 'moment';

import RecentOrderDetail from '../recent-order-detail';

import { getTopArrowImage } from '../../utils/imageChanges';
import {
  getCartItemCountAndPrice,
  getCartItemCountAndPrice1
} from '../../utils/services';

class FeedbackOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  detailOnClick = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  render() {
    const { theme, data, dat1 } = this.props;
    const { isExpanded } = this.state;
    const { detailOnClick } = this;
    const { order_id, total_payable_amount, txn_date } = dat1;

    // console.log(this.props, ' RRR ');
    const countJson = getCartItemCountAndPrice1(dat1?.[0]);
    // console.log(countJson, ' countJson RRR ');
    return (
      <div className="feedback-or-dtl">
        <div className="feedback-or-dtl__detail-wrapper">
          <div
            className="feedback-or-dtl__detail u_cursor_pointer "
            role="presentation"
            onClick={() => detailOnClick()}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <p className="feedback-or-dtl__detail-title feedback-or-dtl__detail-title_bold">
                Order #{order_id}
              </p>
              <p className="feedback-or-dtl__detail-des">
                {dat1?.products?.length || 0} Items | Rs.
                {total_payable_amount} | {moment(txn_date).format('hh:mm A')}{' '}
              </p>
            </div>
            <img
              className={
                isExpanded
                  ? 'feedback-or-dtl__detail-arrow-isexpand'
                  : 'feedback-or-dtl__detail-arrow'
              }
              src={getTopArrowImage(theme)}
              alt="arrow icon"
            />
          </div>
        </div>
        {!isExpanded ? (
          ''
        ) : (
          <div className>
            <RecentOrderDetail isTrackOrder data={dat1} theme={theme} />
          </div>
        )}
      </div>
    );
  }
}

export default FeedbackOrderDetail;
