import {
  LocalStorage,
  SessionStorage
} from '../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../burger-king-lib/constants/app-constants';
import { THEME } from '../constants/app-constants';

const { RESTAURANT } = THEME;

export function homeApiListPayload(theme) {
  const latLng = LocalStorage.get(LocalSessionKey.LAT_LNG);
  const outletId = SessionStorage.get(LocalSessionKey.OUTLET_ID);
  let payload;
  if ((theme === RESTAURANT || theme === 'takeaway') && outletId) {
    payload = {
      lat: Number(latLng && latLng.lat) || 0,
      long: Number(latLng && (latLng.long || latLng.lng)) || 0,
      outletId: SessionStorage.get(LocalSessionKey.OUTLET_ID)
    };
  } else if (theme === 'delivery') {
    payload = {
      lat: Number(latLng && latLng.lat) || 0,
      long: Number(latLng && (latLng.long || latLng.lng)) || 0
    };
  } else {
    payload = {
      lat: Number(latLng && latLng.lat) || 0,
      long: Number(latLng && (latLng.long || latLng.lng)) || 0
    };
  }

  return payload;
}
export function menuListPayload() {}
