import CleverTap from 'clevertap-web-sdk';

function trackEvent(eventName, eventData) {
  trackWithCleverTap(eventName, eventData);
}

function trackWithCleverTap(eventName, eventData) {
  // console.log(`CleverTap Event Triggered: ${eventName} with data:`, eventData);
  CleverTap.event.push(eventName, eventData);
}

export { trackEvent };
