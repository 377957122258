import './index.scss';

import React from 'react';

import moment from 'moment';
import TakeAwayIcon from '../../../assets/images/take-away-black.svg';
import DineInIcon from '../../../assets/images/dine-in-black.svg';
import DeliveryIcon from '../../../assets/images/delivery-black.svg';
import ParkingIcon from '../../../assets/images/parking-black.svg';
import {
  getCallIcon,
  getDirectionImage,
  getRestaurantFinderImage,
  getDeliveryIcon,
  getParkingIcon,
  getTakeawayIcon
} from '../../utils/imageChanges';

const getTime = minutes => {
  let hours;
  let restMinutes;
  const onedayMinutes = 1440; // 24*60

  if (minutes < 60) {
    return `${minutes} mins`;
  }
  if (minutes > 60 && minutes < onedayMinutes) {
    hours = Math.floor(minutes / 60);
    restMinutes = minutes % 60;
    return `${hours} hrs ${restMinutes} mins`;
  }
  const days = Math.floor(minutes / 60 / 24);
  restMinutes = minutes % onedayMinutes;
  hours = Math.floor(restMinutes / 60);
  restMinutes %= 60;
  return `${days} days ${hours} hrs ${restMinutes} mins`;
};

const RestaurantCard = props => {
  const {
    data,
    onClick,
    theme,
    isRestaurantFinder,
    onCallClick,
    outletList,
    index,
    getNavigation
  } = props;
  const {
    outlet_name,
    address,
    takeaway,
    dine_in,
    parking,
    is_delivery,
    distance,
    time_to_travel,
    phone_no,
    lat,
    long,
    outlet_address,
    isActive,
    closes_at,
    opens_at,
    message
  } = data;

  const isOpen = message === 'Open Now';
  const opens = moment(opens_at, 'HH:mm:ss').format('h.mm A');
  const closes = moment(closes_at, 'HH:mm:ss').format('h.mm A');

  // Create a handleClick function that only calls onClick when restaurant is open
  const handleClick = () => {
    if (isOpen) {
      onClick(data);
    }
  };

  return (
    <div
      className={`restaurant-card ${
        isOpen ? 'u_cursor_pointer' : 'u_cursor_default'
      }`}
      role="presentation"
      onClick={handleClick}
      style={{
        marginBottom: outletList.length === index + 1 ? '7rem' : '',
        opacity: isOpen ? 1 : 0.7 // Optional: reduce opacity for closed restaurants
      }}
    >
      <div className="restaurant-card__content">
        <div className="restaurant-card__details_wrapper">
          <div className="restaurant-card__details">
            <div>
              <p className="restaurant-card__name">{outlet_name}</p>
              <p className="restaurant-card__timings">
                Store Timing ({opens}-{closes})
              </p>
              <p className="restaurant-card__description">{address}</p>
              <p
                className={
                  isOpen ? 'restaurant-card__time' : 'restaurant-card__closed'
                }
              >
                {message}
              </p>
              <p className="restaurant-card__range">
                {distance} km away · {getTime(time_to_travel)}
              </p>
            </div>
            {isRestaurantFinder ? (
              <div>
                <div className="restaurant-card__contact">
                  <img
                    src={getCallIcon(theme)}
                    alt="call"
                    className={`restaurant-card__contact-img ${
                      !isOpen ? 'disabled' : ''
                    }`}
                    onClick={e => {
                      if (isOpen) {
                        e.stopPropagation();
                        onCallClick(phone_no);
                      }
                    }}
                    role="presentation"
                    style={{ cursor: isOpen ? 'pointer' : 'default' }}
                  />
                </div>
                <div className="restaurant-card__contact">
                  {' '}
                  <img
                    src={getDirectionImage(theme)}
                    alt="direction"
                    className={`restaurant-card__contact-img ${
                      !isOpen ? 'disabled' : ''
                    }`}
                    onClick={e => {
                      if (isOpen) {
                        e.stopPropagation();
                        getNavigation(address, lat, long);
                      }
                    }}
                    role="presentation"
                    style={{ cursor: isOpen ? 'pointer' : 'default' }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {isRestaurantFinder ? (
            ''
          ) : (
            <div className="restaurant-card__divider" />
          )}
          <div
            className={`restaurant-card__options ${(takeaway ||
              dine_in ||
              parking ||
              is_delivery) &&
              'border'}`}
          >
            {takeaway ? (
              <div className="restaurant-card__list">
                <img
                  src={isOpen ? getTakeawayIcon(theme) : TakeAwayIcon}
                  alt="take away"
                  className="restaurant-card__img"
                />
                <span
                  className={`restaurant-card__list-name ${
                    isOpen ? `${theme}` : 'disabled'
                  }`}
                >
                  Takeaway
                </span>
              </div>
            ) : (
              ''
            )}

            {dine_in ? (
              <div className="restaurant-card__list">
                <img
                  src={isOpen ? getRestaurantFinderImage(theme) : DineInIcon}
                  alt="take away"
                  className="restaurant-card__img"
                />
                <span
                  className={`restaurant-card__list-name ${
                    isOpen ? `${theme}` : 'disabled'
                  }`}
                >
                  Dine-in
                </span>
              </div>
            ) : (
              ''
            )}
            {parking ? (
              <div className="restaurant-card__list">
                <img
                  src={isOpen ? getParkingIcon(theme) : ParkingIcon}
                  alt="take away"
                  className="restaurant-card__img"
                />
                <span
                  className={`restaurant-card__list-name ${
                    isOpen ? `${theme}` : 'disabled'
                  }`}
                >
                  Parking
                </span>
              </div>
            ) : (
              ''
            )}
            {is_delivery ? (
              <div className="restaurant-card__list">
                <img
                  src={isOpen ? getDeliveryIcon(theme) : DeliveryIcon}
                  alt="take away"
                  className="restaurant-card__img"
                />
                <span
                  className={`restaurant-card__list-name ${
                    isOpen ? `${theme}` : 'disabled'
                  }`}
                >
                  Delivery
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
