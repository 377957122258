import './index.scss';

import React from 'react';
import moment from 'moment';

import { getRightArrowImage } from '../../utils/imageChanges';

import TickMarkImage from '../../../assets/images/tickmark.svg';
import Button from '../button';

const getProductNames = data => {
  let temp = '';
  if (data && data.length) {
    data.forEach(item => {
      const { product_name, variant_name } = item;
      if (temp) temp += ' ,  ';
      temp += variant_name ? `${product_name} (${variant_name})` : product_name;
    });
  }
  return temp;
};

const OrderHistoryList = props => {
  const { data, onClick, theme, onFeedBackClick } = props;
  const {
    order_id,
    products,
    total_payable_amount,
    order_placed_at,
    id,
    is_feedback_submitted,
    rider_details
  } = data;
  const status = data.status
    ? data.status.charAt(0) + data.status.toLowerCase().slice(1)
    : '';
  return (
    <div className="order-his-list u_cursor_pointer" role="presentation">
      <div
        className="order-his-list__title"
        role="presentation"
        onClick={() => onClick(data)}
      >
        {`Order #${order_id}`}
        <div>
          {(data?.status === 'RIDER_ALLOCATED' ||
            data?.status === 'DISPATCHED') &&
            data?.delivery_pin && (
              <span
                className={`order-his-list__statusDeliveryPin  ${data?.delivery_pin}`}
              >
                Delivery Pin: {data?.delivery_pin}
              </span>
            )}

          <span className={`order-his-list__status  ${status}`}>{status}</span>
          <img src={getRightArrowImage(theme)} alt="right" />
        </div>
      </div>
      <div className="order-his-list__names">{getProductNames(products)}</div>
      <p className="order-his-list__des">
        {`Rs.${total_payable_amount.toFixed(2)} | ${moment
          .utc(order_placed_at)
          .format('DD MMM, hh:mm A')}`}
      </p>
      {/* {console.log(status, 'status')} */}
      <p className="order-his-list__est">
        {is_feedback_submitted === false &&
          (status?.toLowerCase() == 'delivered' ||
            status?.toLowerCase() == 'pickedup') && (
            <Button
              content="Rate your order"
              theme={theme}
              onClick={() => onFeedBackClick(id, data)}
              className="order-his-list__button"
              data={data}
            />
          )}
      </p>
    </div>
  );
};

export default OrderHistoryList;
