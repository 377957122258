import {
  SessionStorage,
  LocalStorage
} from '../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../burger-king-lib/constants/app-constants';
// import { THEME } from '../constants/app-constants';

// const { RESTAURANT, DELIVERY } = THEME;

export const deliveryAddressStorage = {
  get() {
    return SessionStorage.get('DELIVERY_ADDRESS');
  },
  set(value) {
    SessionStorage.set('DELIVERY_ADDRESS', value);
  }
};

export const restaurantAddressStorage = {
  get() {
    // return this.address;
    return SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
  },
  set(value) {
    SessionStorage.set(LocalSessionKey.RESTAURANT_DETAIL, value);
    // this.address = value;
  }
};

export function getCartItemCountAndPrice(data) {
  if (data && data.items_count) {
    return {
      price: data.total_price || 0,
      quantity: data.items_count,
      offer: data.offer_detail
    };
  }
  if (data && data.length) {
    const [firEl] = data;
    let quantityValue = 0;
    const { delivery_charges, packing_charges, total_payable_amount } = firEl;
    if (firEl && firEl.products) {
      firEl.products.map(item => {
        const { quantity } = item;
        if (quantity) quantityValue += quantity;
        return item;
      });
    }
    return {
      price: (firEl && firEl.total_price) || 0,
      quantity: quantityValue,
      delivery_charges,
      packing_charges,
      total_payable_amount
    };
  }
  return {
    price: 0,
    quantity: 0,
    delivery_charges: 0,
    packing_charges: 0,
    total_payable_amount: 0
  };
}
export function getCartItemCountAndPrice1(data) {
  if (data && data.items_count) {
    return {
      price: data.total_price || 0,
      quantity: data.items_count,
      offer: data.offer_detail
    };
  }
  if (data && data.cart_items) {
    let quantityValue = 0;
    const {
      delivery_charges,
      packaging_charges: packing_charges,
      total_payable_amount,
      total_taxable_amount
    } = data;
    Object.values(data.cart_items).map(item => {
      const { quantity } = item;
      if (quantity) quantityValue += quantity;
      return item;
    });
    return {
      price: total_taxable_amount,
      quantity: quantityValue,
      delivery_charges,
      packing_charges,
      mprice: total_taxable_amount - packing_charges - delivery_charges,
      total_payable_amount
    };
  }
  return {
    price: 0,
    quantity: 0,
    delivery_charges: 0,
    packing_charges: 0,
    total_payable_amount: 0
  };
}

export function generateSelectedAddonsName(data) {
  const { addons } = data;
  let addonConcat = '';
  if (addons && Object.values(addons).length)
    Object.values(addons).forEach(addonData => {
      if (addonConcat) addonConcat += ' + ';
      addonConcat +=
        addonData.quantity > 1
          ? `${addonData.product_name} * ${addonData.quantity}`
          : addonData.product_name;
      return addonData;
    });
  return addonConcat;
}

export function getCartTotalPayableAmount(cartData, theme, orderType) {
  const { delivery_charges, packing_charges, total_payable_amount } = cartData;
  const tempPrice = total_payable_amount;
  return tempPrice;
}

export function getPriceDelimitedString(value) {
  if (value) {
    return Number(value)
      .toFixed(2)
      .toLocaleString();
  }
  return value;
}

export function getCategoryImage(imageUrls, categoryName) {
  if (imageUrls) {
    const filterCategory = imageUrls?.filter(
      data => data?.category === categoryName
    );
    const imageArr =
      filterCategory && filterCategory[0] && filterCategory[0]?.images;

    const { innerWidth } = window;
    let name = 'desktop';

    if (innerWidth <= 400) name = 'mobile_sm';
    else if (innerWidth <= 996) name = 'mobile_lg';

    if (imageArr && imageArr?.length) {
      const imageFilter = imageArr?.filter(data => data?.name === name);
      return imageFilter && imageFilter[0] && imageFilter[0]?.image;
    }
    return {};
  }

  return {};
}

export function setInitialPayment() {
  const restaurantDetail = JSON.parse(
    window?.sessionStorage?.getItem('RESTAURANT_DETAIL')
  );
  return restaurantDetail?.is_razorpay_enabled ? 'RAZORPAY' : 'JUSPAY';
}

export function getImageUrls(imageArr) {
  const { innerWidth } = window;
  let name = 'desktop';

  if (innerWidth <= 576) name = 'mobile_sm';
  if (innerWidth <= 996) name = 'mobile_lg';

  const imageFilter = imageArr.filter(data => data.name === name);
  return imageFilter && imageFilter[0];
}

export function getMobileHeight(size) {
  const { innerWidth } = window;
  const newSize = size * (innerWidth / 375);
  return Math.round(newSize);
}

export function getDesktopHeight(size) {
  let { innerWidth } = window;
  if (innerWidth > 1920) innerWidth = 1920;
  const newSize = size * (innerWidth / 1440);
  return Math.round(newSize);
}

export function splitAddress(address) {
  let newAddress;
  if (!address) {
    newAddress = '';
  } else {
    newAddress = address
      .replace(/[' ']/g, '+')
      .replace(/[',']/g, '%2C')
      .replace(/['#']/g, '%23')
      .replace(/[' & ']/g, '%26')
      .replace(/[' ( ']/g, '%28')
      .replace(/[' ) ']/g, '%29');
  }
  return newAddress;
}

export const cartValueStorage = {
  get() {
    return LocalStorage.get('CART DETAILS');
  },
  set(value) {
    LocalStorage.set('CART DETAILS', value);
  },
  clearAll() {
    LocalStorage.clearAll();
  }
};

export const cartDetails = {
  get() {
    return LocalStorage.get('CART VALUES');
  },
  set(value) {
    LocalStorage.set('CART VALUES', value);
  },
  clearAll() {
    LocalStorage.clearAll();
  }
};

export const getUserID = () => {
  return LocalStorage.get(LocalSessionKey.USER_ID);
};

export const getUserData = () => {
  return LocalStorage.get(LocalSessionKey.USER_DATA);
};
