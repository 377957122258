/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import Logout from '../../components/log-out';
import BKLogo from '../../../assets/images/logo.png';
import { getRightArrowImage } from '../../utils/imageChanges';
import { ROUTES as _routes, THEME } from '../../constants/app-constants';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { getToken } from '../../burger-king-lib/redux/home/actions';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import { trackEvent } from '../../../analytics_wrapper';
import {
  faqs,
  logout,
  notifications_settings,
  privacy_policy,
  terms_conditions
} from '../../../analyics_constants';

const { THEMESTORAGE } = THEME;

class Settings extends Component {
  constructor(props) {
    super(props);
    const { activeKey } = props;
    this.state = {
      activeKey: activeKey || '',
      showLogOutModal: false
    };
  }

  goBackOnClick = () => {
    const { history } = this.props;
    this.props.handleShowSettingsList(false);
    history.go(-1);
  };

  termsClickHandler = () => {
    const { history, theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    history.push(_routes.TERMS_AND_CONDITIONS);
    trackEvent(terms_conditions, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
  };

  privacyClickHandler = () => {
    const { history, theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    history.push(_routes.PRIVACY_POLICY);
    trackEvent(privacy_policy, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
  };

  pprivacyClickHandler = () => {
    const { history } = this.props;
    history.push(_routes.PPROMOTIONAL_PRIVACY_POLICY_LINK);
  };

  cClickHandler = () => {
    const { history } = this.props;
    history.push(_routes.FEEDBACK);
  };

  notificationSettingsClickHandler = () => {
    const { history, theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    history.push(_routes.NOTIFICATION_SETTINGS);
    trackEvent(notifications_settings, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
  };

  helpSupportClickHandler = () => {
    const { history, theme } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    history.push(_routes.HELP_SUPPORT);
    trackEvent(faqs, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
  };

  logoutClickHandler = () => {
    this.setState({ showLogOutModal: true });
  };

  yesClickHandler = () => {
    const theme = SessionStorage.get(THEMESTORAGE);
    const orderType = SessionStorage.get(LocalSessionKey.ORDER_TYPE);
    // localStorage.clear();
    // sessionStorage.clear();
    const { history, getTokenData } = this.props;
    const addressInfo = SessionStorage.get(LocalSessionKey.RESTAURANT_DETAIL);
    trackEvent(logout, {
      order_mode: SessionStorage.get(LocalSessionKey.ORDER_TYPE),
      city: addressInfo?.city,
      mobile_no: LocalStorage.get(LocalSessionKey.USER_DATA),
      crown_points: window.localStorage.getItem('crowns')
        ? window.localStorage.getItem('crowns')
        : 0
    });
    getTokenData();
    const script = document.createElement('script');
    script.onload = () => {
      smartech(
        'contact',
        6,
        { 'pk^mobile': `${LocalStorage.get(LocalSessionKey.USER_DATA)}` },
        () => {
          smartech(
            'identify',
            `${LocalStorage.get(LocalSessionKey.USER_DATA)}`
          );
          smartech('dispatch', 'af_logout', {
            mobile: `${LocalStorage.get(LocalSessionKey.USER_DATA)}`
          });
        }
      );
    };
    script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    document.head.appendChild(script);
    SessionStorage.set(THEMESTORAGE, theme);
    SessionStorage.set(LocalSessionKey.ORDER_TYPE, orderType);
    LocalStorage.set(LocalSessionKey.USER_ID, '');
    LocalStorage.set(LocalSessionKey.USER_DATA, '');
    SessionStorage.set('IS_COD_BLOCK', '');
    SessionStorage.set('is_charity', true);
    history.push(_routes.HOME);
  };

  cancelClickHandler = () => {
    this.setState({ showLogOutModal: false });
  };

  render() {
    const { goBackOnClick } = this;
    const { theme } = this.props;
    const { activeKey, showLogOutModal } = this.state;
    const { innerWidth } = window;
    return (
      <div className="settings-pg_wrapper">
        {innerWidth < 1024 ? (
          <AccountSubSecTitle
            title="Settings"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
        ) : (
          ''
        )}

        <div className="settings-pg">
          {innerWidth < 1023 ? (
            <div className="settings-pg__logo">
              <div>
                <img src={BKLogo} alt="logo" width="79" height="79" />
              </div>
              <div className="settings-pg__version-info">
                <p className="settings-pg__version-title">
                  Restaurant Brands Asia Limited
                </p>
                <p className="settings-pg__version-copyright">
                  &copy; {new Date().getFullYear()} Burger King
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="settings-pg__options">
            <ul className=" u_list_style_none ">
              <li
                className={`settings-pg__list ${
                  activeKey === 'help-support' ? 'settings-pg__list_active' : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  role="presentation"
                  onClick={() => this.helpSupportClickHandler()}
                >
                  <p className="settings-pg__name">FAQ's & Support</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'notification-settings'
                    ? 'settings-pg__list_active'
                    : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  role="presentation"
                  onClick={() => this.notificationSettingsClickHandler()}
                >
                  <p className="settings-pg__name">Notification Settings</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'terms-conditions'
                    ? 'settings-pg__list_active'
                    : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  onClick={() => this.termsClickHandler()}
                  role="presentation"
                >
                  <p className="settings-pg__name">Terms & Conditions</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'privacy-policy'
                    ? 'settings-pg__list_active'
                    : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  onClick={() => this.privacyClickHandler()}
                  role="presentation"
                >
                  <p className="settings-pg__name">Privacy Policy</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'promotional-terms'
                    ? 'settings-pg__list_active'
                    : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  onClick={() => this.pprivacyClickHandler()}
                  role="presentation"
                >
                  <p className="settings-pg__name">Promotional T & C</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'customer-care'
                    ? 'settings-pg__list_active'
                    : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  onClick={() => this.cClickHandler()}
                  role="presentation"
                >
                  <p className="settings-pg__name">Customer Care</p>
                  <img src={getRightArrowImage(theme)} alt="right arrow" />
                </div>
              </li>
              <li
                className={`settings-pg__list ${
                  activeKey === 'logout' ? 'settings-pg__list_active' : ''
                }`}
              >
                <div
                  className="settings-pg__card"
                  onClick={() => this.logoutClickHandler()}
                  role="presentation"
                >
                  <p className="settings-pg__name">Log Out</p>
                </div>
              </li>
            </ul>
          </div>
          {showLogOutModal ? (
            <Logout
              theme={theme}
              onYesClick={() => this.yesClickHandler()}
              onCancelClick={() => this.cancelClickHandler()}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    homeData: state.home
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTokenData: payloadData => dispatch(getToken(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
