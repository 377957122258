import {
  GET_BKWALL_DATA,
  GET_BKWALL_DATA_ERROR,
  GET_BKWALL_DATA_SUCCESS,
  GET_CART_OFFERS,
  GET_CART_OFFERS_ERROR,
  GET_CART_OFFERS_SUCCESS,
  GET_COUPONS,
  GET_COUPONS_ERROR,
  GET_COUPONS_SUCCESS,
  TIPS_LISTING_API,
  TIPS_LISTING_API_SUCCESS,
  TIPS_LISTING_API_ERROR,
  GET_CROWN_HISTORY,
  GET_CROWN_HISTORY_ERROR,
  GET_CROWN_HISTORY_SUCCESS,
  GET_DAILY_REWARDS,
  GET_DAILY_REWARDS_ERROR,
  GET_DAILY_REWARDS_SUCCESS,
  GET_FREE_CROWN,
  GET_FREE_CROWN_ERROR,
  GET_FREE_CROWN_SUCCESS,
  GET_LIMITED_DEALS,
  GET_LIMITED_DEALS_ERROR,
  GET_LIMITED_DEALS_SUCCESS,
  GET_OFFERS,
  GET_OFFERS_ERROR,
  GET_OFFERS_SUCCESS,
  GET_ORGANIZATION_LIST,
  GET_ORGANIZATION_LIST_ERROR,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_TOP_DEALS,
  GET_TOP_DEALS_ERROR,
  GET_TOP_DEALS_SUCCESS,
  GET_USER_AVAILABLE_POINTS,
  GET_USER_AVAILABLE_POINTS_ERROR,
  GET_USER_AVAILABLE_POINTS_SUCCESS,
  GET_USER_COUPONS,
  GET_USER_COUPONS_ERROR,
  GET_USER_COUPONS_SUCCESS,
  CREATE_CLUB_OFFER_ONBOARDING,
  CREATE_CLUB_OFFER_ONBOARDING_SUCCESS,
  CREATE_CLUB_OFFER_ONBOARDING_ERROR
} from '../actionTypes';
const initialState = {
  getOffers: {},
  getUserAvailablePoints: {},
  getCoupons: {},
  getUserCoupons: {},
  tipsListing: {},
  termsAndCondition: {}
};

export default function OffersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFERS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getOffers: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_OFFERS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        getOffers: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_OFFERS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getOffers: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_USER_AVAILABLE_POINTS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getUserAvailablePoints: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_AVAILABLE_POINTS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        getUserAvailablePoints: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_AVAILABLE_POINTS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getUserAvailablePoints: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_COUPONS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getCoupons: {},
        meta: action.meta,
        Error: null
      };
    }
    case GET_COUPONS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        getCoupons: action.value,
        meta: action.meta,
        Error: null
      };
    }
    case GET_COUPONS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getCoupons: {},
        meta: action.meta,
        Error: action.error
      };
    }

    case TIPS_LISTING_API: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        tipsListing: {},
        meta: action.meta,
        Error: null
      };
    }
    case TIPS_LISTING_API_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        tipsListing: action.value,
        meta: action.meta,
        Error: null
      };
    }
    case TIPS_LISTING_API_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        tipsListing: {},
        meta: action.meta,
        Error: action.error
      };
    }

    case GET_USER_COUPONS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getUserCoupons: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_COUPONS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        getUserCoupons: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_COUPONS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getUserCoupons: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_FREE_CROWN: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        freeCrowns: {},
        meta: action.meta,
        Error: null
      };
    }
    case GET_FREE_CROWN_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        freeCrowns: action.value,
        meta: action.meta,
        Error: null
      };
    }
    case GET_FREE_CROWN_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        freeCrowns: {},
        meta: action.meta,
        Error: action.error
      };
    }

    case GET_LIMITED_DEALS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        limitedDeals: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_LIMITED_DEALS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        limitedDeals: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_LIMITED_DEALS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        limitedDeals: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_TOP_DEALS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        topDeals: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_TOP_DEALS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        topDeals: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_TOP_DEALS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        topDeals: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_DAILY_REWARDS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        dailyRewards: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_DAILY_REWARDS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        dailyRewards: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_DAILY_REWARDS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        dailyRewards: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_CROWN_HISTORY: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        crownHistory: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_CROWN_HISTORY_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        crownHistory: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_CROWN_HISTORY_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        crownHistory: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case GET_BKWALL_DATA: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        bkwallData: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_BKWALL_DATA_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        bkwallData: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_BKWALL_DATA_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        bkwallData: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_CART_OFFERS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        cartOffers: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_CART_OFFERS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        cartOffers: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_CART_OFFERS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        cartOffers: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_ORGANIZATION_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        organizationList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_ORGANIZATION_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        organizationList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_ORGANIZATION_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        organizationList: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case CREATE_CLUB_OFFER_ONBOARDING: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        clubOffer: {},
        Error: null,
        meta: action.meta
      };
    }
    case CREATE_CLUB_OFFER_ONBOARDING_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        clubOffer: action.value,
        Error: null,
        meta: action.meta
      };
    }

    case CREATE_CLUB_OFFER_ONBOARDING_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        clubOffer: {},
        Error: action.error,
        meta: action.meta
      };
    }

    default: {
      return { ...state };
    }
  }
}
