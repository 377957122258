import React from 'react';
import './index.scss';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Fssai from '../../../assets/images/fssaiIcon.svg';
import Footer from '../../containers/footer';

const Licence = ({ theme }) => {
  const history = useHistory();

  const goBackOnClick = () => {
    if (history) {
      history.go(-1);
    }
  };

  const isMobile = window.innerWidth < 1023;

  return (
    <>
      {isMobile && (
        <AccountSubSecTitle
          title="FSSAI"
          goBackOnClick={goBackOnClick}
          theme={theme}
        />
      )}
      <div className="pageWrapper">
        <div className={isMobile ? 'fssaiBoxMob' : 'fssaiBox'}>
          <div className="fssaiContainer">
            <div className="fssai">
              <img src={Fssai} alt="FSSAI Icon" />
            </div>
            <div className="license">License No. 10015022003924</div>
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </>
  );
};

export default Licence;
