/* eslint-disable no-else-return */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable quotes */
import {
  ADD_TO_REDIS_CART_API,
  BASE_URL,
  CLEAR_REDIS_CART_API,
  CREATE_TOKEN_API,
  GET_LEGAL_API,
  INVESTORTREELIST,
  PRODUCT_LIST_API2,
  REFRESH_TOKEN_API,
  VERIFY_OTP
} from '../constants/api-constants';
import { LocalSessionKey } from '../constants/app-constants';
import { LocalStorage, SessionStorage } from './storage.web';
// import { CLEAR_REDIS_CART_API } from './storage.web';

const axios = require('axios');
const refreshAxios = require('axios');
// import NetInfo from "@react-native-community/netinfo";

const SHA256 = require('crypto-js/sha256');

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_PATCH = 'PATCH';
const METHOD_DELETE = 'DELETE';

let failedRequests = [];
let isRefreshing = false;

const getHeaders = async (
  isReturnAccessToken = true,
  isUpload,
  end_url,
  payload,
  method,
  excludeUserID
) => {
  const user_id = await LocalStorage.get(LocalSessionKey.USER_ID);
  const orderType = await SessionStorage.get(LocalSessionKey.ORDER_TYPE);
  const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  const appVersion = await SessionStorage.get(LocalSessionKey.APP_VERSION);
  const timestamp = new Date().getTime().toString();
  const authorization = await LocalStorage.get(LocalSessionKey.TOKEN);
  const deviceName = await SessionStorage.get(LocalSessionKey.DEVICE_NAME);
  const osVersion = await SessionStorage.get(LocalSessionKey.OS_VERSION);
  const deviceBrand =
    (await SessionStorage.get(LocalSessionKey.DEVICE_BRAND)) ||
    (await SessionStorage.get(LocalSessionKey.OS_NAME));
  const deviceModel =
    (await SessionStorage.get(LocalSessionKey.DEVICE_MODEL)) ||
    (await SessionStorage.get(LocalSessionKey.OS_NAME));

  const headers = {
    'Content-Type': 'application/json',
    ...(excludeUserID ? {} : { userId: user_id || '0' }),
    type: orderType,
    platform,
    appVersion,
    timestamp,
    authorization: authorization || '',
    deviceName,
    osVersion,
    deviceBrand,
    deviceModel,
    'Access-Control-Allow-Origin': '*',
    'X-Frame-Options': 'SAMEORIGIN'
  };
  const myFunction = async data => {
    if (Object.keys(data).length > 10) {
      headers.investor_tree_id = payload;
    }
  };

  const getSessionOutletID = () => {
    if (
      window &&
      window.sessionStorage &&
      window.sessionStorage.getItem('OUTLET_ID')
    ) {
      return window.sessionStorage.getItem('OUTLET_ID');
    }
    return null;
  };

  if (end_url === INVESTORTREELIST) {
    myFunction(payload);
  }
  if (end_url === CREATE_TOKEN_API) {
    headers.userId = 0;
  }
  if (end_url.includes(PRODUCT_LIST_API2)) {
    const val = getSessionOutletID();
    headers.outlet_id = val || '32236';
  }
  if (end_url.includes(VERIFY_OTP)) {
    const val = getSessionOutletID();
    headers.outlet_id = val || '32236';
  }
  if (end_url.includes(GET_LEGAL_API)) {
    headers.authorization =
      'eyJ0eXAiOiJKV1QiLCPRASHanthGVudGl0eSI6IlRFTVAzODEzODE4MjIxNjI2NjY3OTI1IiwiZXreddyfGiHsHJniGqehU5cUPx';
  }
  if (end_url.includes(CLEAR_REDIS_CART_API)) {
    const val = getSessionOutletID();
    headers.outlet_id = val || '32236';
  }
  if (end_url.includes(ADD_TO_REDIS_CART_API)) {
    const val = getSessionOutletID();
    headers.outlet_id = val || '32236';
  }
  if (isUpload) delete headers['Content-Type'];
  if (method !== 'GET' && authorization) {
    const encryptionkeyStr =
      authorization +
      timestamp +
      user_id +
      end_url +
      (Object.keys(payload).length === 0 && payload.constructor === Object
        ? ''
        : JSON.stringify(payload).replace(/ /g, ''));
    headers.encryptionkey = SHA256(encryptionkeyStr).toString();
  } else if (authorization) {
    headers.encryptionkey = SHA256(
      authorization + timestamp + user_id + end_url.split('?')[0]
    ).toString();
  }
  return headers;
};

const processfailedRequests = (error, token = null) => {
  failedRequests.forEach(promise => {
    if (error) {
      console.log('error:', error);
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedRequests = [];
};

const getNewRefreshTokenCall = async (promiseObj, config) => {
  const url = BASE_URL + REFRESH_TOKEN_API;
  const getToken = async () => {
    const result = await (window &&
      window.localStorage.getItem('REFRESH_TOKEN'));
    return result;
  };
  const getPayload = async () => {
    const payload = {
      refresh_token: await getToken()
    };
    return JSON.stringify(payload);
  };
  const payload = await getPayload();
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url,
      data: payload
    };
    refreshAxios(options)
      .then(res => {
        if (res && res.data && res.data.content) {
          const newAccessToken = res.data.content.token;
          config.headers.authorization = newAccessToken;
          LocalStorage.set(LocalSessionKey.TOKEN, res.data.content.token);
          LocalStorage.set(
            LocalSessionKey.REFRESH_TOKEN,
            res.data.content.refresh_token
          );
          // console.log('refresh Token response==>', res.data.content.token);
          isRefreshing = false;
          processfailedRequests(null, newAccessToken);
          promiseObj.resolve(axios(config));
        }
      })
      .catch(err => {
        console.log('Refresh AuthLogic API Failed:', err);
        processfailedRequests(err, null);
        alert('Session timeout kindly login again');
        window && window.localStorage && window.localStorage.clear();
        window && window.localStorage && window.sessionStorage.clear();
        // window &&
        //   window.location &&
        // window.location.replace(process.env.REACT_APP_API_ENDPOINT);
        // SessionStorage.set(LocalSessionKey.USER_LOGOUT, 'true');logout functionality
        // window.history.
        promiseObj.reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  } catch (error) {
    promiseObj.reject(error);
  }
};

// Response interceptor for API calls Response
axios.interceptors.response.use(
  response => {
    // console.log(
    //   'Interceptor Response ** Success ** for API: ',
    //   response.config.url
    // );
    // Return all success response
    return response;
  },
  error => {
    const { response, config } = error;
    let errorResponse = null;
    if (!config || !response) {
      if (error.message === 'Network Error') {
        errorResponse = {
          error: {
            code: 503,
            message: error.message
          }
        };
      }
      return errorResponse;
    }
    // Return any error which is not due to authentication back to the calling service

    if (
      (response?.status == 401 || response?.status == 403) &&
      !config?.__isRetryRequest
    ) {
      if (isRefreshing) {
        try {
          return new Promise(function(resolve, reject) {
            failedRequests.push({ resolve, reject });
          })
            .then(token => {
              // Call Success ---> 1
              config.headers.authorization = token;
              return axios.request(config);
            })
            .catch(err => {
              // Call error ---> 2
              return Promise.reject(err);
            });
        } catch (e) {
          console.log('error interceptors==>', e);
          return e;
        }
      }
      isRefreshing = true;
      config.__isRetryRequest = true;

      // Try request again with new token
      // console.log('camehere=== outside', {}, config, config.__isRetryRequest);
      return new Promise(function(resolve, reject) {
        // Get new refresh token
        getNewRefreshTokenCall({ resolve, reject }, config);
      });
    }
    return new Promise((resolve, reject) => {
      // console.log('Interceptor Response: ', error.response);
      reject(error);
    });
  }
);

export async function makeHttpRequestForWeb(
  endPoint_url,
  method,
  payload,
  isUpload,
  isFetchMethod = false,
  excludeUserID = false
) {
  const url = BASE_URL + endPoint_url;
  const headers = await getHeaders(
    false,
    isUpload,
    endPoint_url,
    payload,
    method,
    excludeUserID
  );
  return new Promise((resolve, reject) => {
    try {
      if (url === null || url === undefined || url === '') {
        reject(new Error('URL not present.'));
      } else {
        let options = {};

        if (method === 'GET') {
          options = {
            method,
            url,
            headers,
            otherdata: { isUpload, endPoint_url, payload, method }
          };
        } else if (isUpload) {
          options = {
            method,
            headers,
            url,
            data: payload,
            otherdata: { isUpload, endPoint_url, payload, method }
          };
        } else {
          options = {
            method,
            headers,
            url,
            data: JSON.stringify(payload),
            otherdata: { isUpload, endPoint_url, payload, method }
          };
        }
        if (isFetchMethod) {
          const fetchOptions = {
            method: method,
            headers: headers,
            body: JSON.stringify(payload)
          };
          fetch(url, fetchOptions)
            .then(async response => {
              const responseData = await response.json();
              if (!response.ok) {
                reject({
                  status: response.status,
                  data: responseData
                });
              } else {
                resolve({
                  status: response.status,
                  data: responseData
                });
              }
            })
            .catch(error => {
              reject(error);
            });
        } else {
          axios(options)
            .then(response => {
              if (response.error && response.error.code === 503) {
                reject({
                  error: true,
                  message: response.error.message,
                  code: 503
                });
              } else {
                resolve(response.data);
              }
            })
            .catch(error => {
              if (error && error.response && error.response.status === 400) {
                resolve(error.response.data);
                return;
              }
              resolve(error);
            });
        }
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function makeHttpRequestForRefreshTokenWeb() {
  const url = BASE_URL + REFRESH_TOKEN_API;
  const getToken = async () => {
    const result = await (window &&
      window.localStorage.getItem('REFRESH_TOKEN'));
    return result;
  };
  const getPayload = async () => {
    const payload = {
      refresh_token: await getToken()
    };
    return JSON.stringify(payload);
  };
  const payload = await getPayload();
  //  {
  //   refresh_token: getToken()
  // };
  // console.log(await getPayload(), 'result');
  return new Promise((resolve, reject) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        url,
        data: payload
      };
      axios(options)
        .then(response => {
          if (response && response.data && response.data.content) {
            LocalStorage.set(
              LocalSessionKey.TOKEN,
              response.data.content.token
            );
            LocalStorage.set(
              LocalSessionKey.REFRESH_TOKEN,
              response.data.content.refresh_token
            );
            resolve(response.data.content.token);
          } else resolve(null);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export async function makeHttpRequest(endPoint_url, method, payload, isUpload) {
  const url = BASE_URL + endPoint_url;
  const headers = await getHeaders(
    false,
    isUpload,
    endPoint_url,
    payload,
    method
  );

  return new Promise((resolve, reject) => {
    try {
      if (url === null || url === undefined || url === '') {
        reject(new Error('URL not present.'));
      } else {
        let options = {};

        if (method === 'GET') {
          options = {
            method,
            headers
          };
        } else if (isUpload) {
          options = {
            method,
            headers,
            body: payload
          };
        } else {
          options = {
            method,
            headers,
            body: JSON.stringify(payload)
          };
        }
        fetch(url, options)
          .then(
            res => {
              if (res.status === 204) {
                return {
                  response: {
                    alert: [{ message: 'No content' }],
                    code: 204
                  }
                };
              } else if (res.status === 419 || res.status === 401) {
                reject({
                  error: true,
                  message: 'token expired',
                  code: res.status
                });
              }
              return res.json();
            },
            error => {
              if (error.message === 'Network request failed') {
                reject({
                  error: true,
                  message: error.message,
                  code: 503
                });
              }
            }
          )
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function makeHttpRequestForRefreshToken(
  endPoint_url,
  method,
  payload
) {
  const url = BASE_URL + endPoint_url;

  return new Promise((resolve, reject) => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      };
      fetch(url, options)
        .then(
          res => {
            return res.json();
          },
          error => {
            if (error.message === 'Network request failed') {
              reject({
                error: true,
                message: error.message,
                code: 503
              });
            }
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export async function get(url, payload = {}, excludeUserID = false) {
  const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  if (platform === 'web')
    return makeHttpRequestForWeb(
      url,
      METHOD_GET,
      payload,
      false,
      false,
      excludeUserID
    );
  return makeHttpRequestForWeb(
    url,
    METHOD_GET,
    payload,
    false,
    false,
    excludeUserID
  );
}

export async function post(
  url,
  payload = {},
  isFetchMethod,
  excludeUserID = false
) {
  const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  if (platform === 'web') {
    return !isFetchMethod
      ? makeHttpRequestForWeb(
          url,
          METHOD_POST,
          payload,
          false,
          false,
          excludeUserID
        )
      : makeHttpRequestForWeb(
          url,
          METHOD_POST,
          payload,
          false,
          true,
          excludeUserID
        );
  } else {
    return !isFetchMethod
      ? makeHttpRequestForWeb(
          url,
          METHOD_POST,
          payload,
          false,
          false,
          excludeUserID
        )
      : makeHttpRequestForWeb(
          url,
          METHOD_POST,
          payload,
          false,
          true,
          excludeUserID
        );
  }
}

export async function upload(url, payload = {}) {
  // const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  // if (platform === 'web') return makeHttpRequestForWeb(url, METHOD_POST, payload, true);
  return makeHttpRequestForWeb(url, METHOD_POST, payload, true);
}

export async function put(url, payload = {}) {
  // const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  // if (platform === 'web') return makeHttpRequestForWeb(url, METHOD_PUT, payload);
  return makeHttpRequestForWeb(url, METHOD_PUT, payload);
}

export async function patch(url, payload = {}) {
  // const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  // if (platform === 'web') return makeHttpRequestForWeb(url, METHOD_PATCH, payload);
  return makeHttpRequestForWeb(url, METHOD_PATCH, payload);
}

export async function del(url, payload = {}) {
  // const platform = await SessionStorage.get(LocalSessionKey.PLATFORM);
  // if (platform === 'web') return makeHttpRequestForWeb(url, METHOD_DELETE, payload);
  return makeHttpRequestForWeb(url, METHOD_DELETE, payload);
}

export async function getAll(urls, payload = {}) {
  return Promise.all(
    urls.map(url => makeHttpRequestForWeb(url, METHOD_GET, payload))
  ).then(data => {
    return data;
  });
}

export async function postAll(urls, payload = {}) {
  return Promise.all(
    payload.data.map(payl =>
      makeHttpRequestForWeb(urls, METHOD_POST, { data: payl })
    )
  ).then(data => {
    return data;
  });
}
